<template>
  <input
    type="text"
    class="form-control"
    :placeholder="placeholder"
    @input="handleInput"
    :value="value"
    :readonly="readonly"
  />
</template>

<script>
export default {
  name: 'FormInput',
  props: ['value', 'placeholder', 'readonly'],
  data() {
    return {};
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  height: 45px;
}
</style>
