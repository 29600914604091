<template>
  <div
    class="list-group list-group-horizontal"
    :class="{ 'top-right': topRight }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ListGroup',
  props: {
    'top-right': {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.top-right {
  margin-left: auto;
}
::v-deep {
  .list-group-item a {
    display: flex;
    align-items: center;
  }
}
</style>
