<template>
  <form @submit.prevent="send">
    <CardComponent>
      <template #header>
        {{ $t('home.invite-card.title') }}
        <ListGroup top-right>
          <ListGroupItem>
            <router-link to="/users">
              <span class="material-icons-outlined"> manage_accounts </span>
            </router-link>
          </ListGroupItem>
        </ListGroup>
      </template>
      <!-- Convide sua Equipe -->
      <FormGroup label="E-mail" row>
        <FormInput v-model="form.email" placeholder="example@example.com" required />
      </FormGroup>
      <FormGroup label="Função" row>
        <FormSelect :options="roles" text-field="label" value-field="value" v-model="form.rolde" required />
      </FormGroup>
      <Button type="submit" :loading="isSending">
        {{ $t('home.invite-card.btn-invite') }}
      </Button>
    </CardComponent>
  </form>
</template>

<script>
import UserService from '@/services/user.service';
import Card from '@/components/rebranding/card/Card.vue';
import ListGroup from '@/components/rebranding/list/ListGroup.vue';
import ListGroupItem from '@/components/rebranding/list/ListGroupItem.vue';
import FormGroup from '@/components/form/FormGroup.vue';
import FormInput from '@/components/form/FormInput.vue';
import FormSelect from '@/components/rebranding/form/FormSelect.vue';
import Button from '@/components/rebranding/button/Button.vue';
import CardComponent from '@/components/CardComponent.vue';

export default {
  name: 'InviteCard',
  components: {
    CardComponent,
    ListGroup,
    ListGroupItem,
    FormGroup,
    FormInput,
    FormSelect,
    Button,
  },
  data() {
    return {
      form: {
        email: '',
        role: 'user',
      },
      isSending: false,
    };
  },
  computed: {
    roles() {
      const roles = [
        {
          label: this.$t('home.invite-card.select.adm'),
          value: 'admin',
        },
        {
          label: this.$t('home.invite-card.select.dev'),
          value: 'developer',
        },
        {
          label: this.$t('home.invite-card.select.fin'),
          value: 'billing',
        },
        {
          label: this.$t('home.invite-card.select.carrier'),
          value: 'user',
        },
      ];
      if (this.$store.state.account.whitelabel_id == null) {
        roles.push(
          {
            label: this.$t('home.invite-card.select.info-security'),
            value: 'security',
          },
          {
            label: this.$t('home.invite-card.select.sale-executive'),
            value: 'sales',
          },
        );
      }
      return roles;
    },
  },
  methods: {
    send() {
      this.isSending = true;
      UserService.invite(this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Convite enviado',
            type: 'success',
          });
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .yu-custom-select .vs__dropdown-toggle {
  width: auto !important;
  height: 38px;
}

form {
  display: contents;
}
</style>
