<template>
  <div class="form-group" :class="{ row }">
    <label v-if="label" :class="{ 'col-sm-3 col-form-label': row }"
      >{{ label }}<span v-if="required" class="text-danger">*</span></label
    >
    <div :class="{ 'col-sm-9': row }">
      <slot></slot>
      <small v-if="description" class="form-text text-muted">{{
        description
      }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    row: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 11px;
  label {
    font-size: 16px !important;
    line-height: 30px !important;
  }
}
</style>
