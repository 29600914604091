<template>
  <div class="card" :class="{ 'card-tabs': tabs }">
    <div v-if="$slots.header" class="d-flex justify-content-between  align-items-center">
      <slot name="header" />
    </div>
    <div class="card-body">
      <h4 v-if="title" class="card-title">{{ title }}</h4>
      <slot></slot>
    </div>
    <div v-if="$slots.footer" class="card-footer" :class="footerClass">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    tabs: {
      type: Boolean,
      default: false,
    },
    footerClass: String,
  },
};
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 0px 2px 4px 0px #0000001a !important;
  .card-header {
    line-height: 43px;
    font-size: 20px;
    padding: 8px 28px 13px 28px;
  }
  &.card-tabs {
    box-shadow: unset !important;
    .card-header {
      padding: 0;
      &::after {
        content: '';
        display: block;
        height: 2px;
        background: var(--clr-yup-purple);
        opacity: 0.3;
        position: absolute;
        bottom: -1px;
        width: 100%;
      }
    }
    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
    .list-group {
      padding: 0 32px;
    }
  }
  .card-body {
    padding: 8px 28px 8px 28px;
  }
}

</style>
