<template>
  <div class="list-group-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ListGroupItem',
  props: {
    'top-right': {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-item {
  border: none;
  padding: 0 10px;
  a {
    font-size: 28px;
    color: #697077;
  }
}
</style>
