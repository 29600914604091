<template>
  <select class="form-control" :value="value" @change="handleInput">
    <option v-if="!required"></option>
    <option
      v-for="(option, n) in options"
      :key="n"
      :value="option[valueField]"
      :disabled="option.disabled"
    >
      {{ option[textField] }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    options: {
      type: Array,
    },
    value: {
      default: '',
    },
    readonly: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    textField: {
      type: String,
      default: 'text',
    },
    valueField: {
      type: String,
      default: 'value',
    },
  },
  data() {
    return {};
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  height: 45px;
}
</style>
