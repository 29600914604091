<template>
  <button
    :type="type"
    class="btn"
    :class="{
      'qt-loader qt-loader-mini qt-loader-right': loading,
      'btn-primary': variant === 'solid',
      'btn-outline-primary': variant === 'outlined',
    }"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'solid',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group-append {
  .btn {
    margin: 0;
    height: 45px;
    padding: 10px 24px;
    .material-icons {
      font-size: 16px !important;
    }
  }
}
.btn {
  padding: 6px 24px;
  border-radius: 8px;
  line-height: 26px;
  font-size: 16px;
  margin: 0.25rem;
  &.qt-loader.qt-loader-right {
    padding-right: 40px;
  }
}
</style>
